import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { LinkAction, NavigationLinks } from '@webcoffee/interfaces';

@Component({
    selector: 'webcoffee-page-navigator',
    templateUrl: './page-navigator.component.html',
    styleUrls: ['./page-navigator.component.scss'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, RouterModule, FlexLayoutModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNavigatorComponent {
    @ViewChild('fileImport', { static: false }) importFile!: ElementRef;

    @Input() navigationLinks!: NavigationLinks;
    @Output() action: EventEmitter<LinkAction> = new EventEmitter();

    emitEvent(e: any) {
        if (e) {
            this.action.emit(e);
            this.importFile.nativeElement.value = '';
        }
    }
}
