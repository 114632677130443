<div class="left-buttons">
    <button mat-raised-button ngClass.gt-xs="me-2" ngClass.xs="small me-1" [routerLink]="link.link" *ngFor="let link of navigationLinks.left" (click)="emitEvent(link.emit)">
        <mat-icon [svgIcon]="link.icon" *ngIf="link.icon"></mat-icon>{{ link.text }}
    </button>
</div>
<div class="right-buttons">
    <ng-container *ngFor="let link of navigationLinks.right">
        <button mat-raised-button color="primary" (click)="fileImport.click()" small class="mx-2" *ngIf="link.import">
            <mat-icon [svgIcon]="link.icon" *ngIf="link.icon"></mat-icon> {{ link.text }}
        </button>
        <input #fileImport type="file" (change)="emitEvent($event)" hidden />
        <button
            mat-raised-button
            [class]="link.classes ? link.classes : ''"
            ngClass.gt-xs="ms-2"
            ngClass.xs="small ms-1"
            [routerLink]="link.link"
            (click)="emitEvent(link.emit)"
            *ngIf="link.text && !link.import"
        >
            <mat-icon [svgIcon]="link.icon" *ngIf="link.icon" class="me-2"></mat-icon>{{ link.text }}
        </button>
        <button
            mat-icon-button
            [class]="link.classes ? link.classes : ''"
            ngClass.gt-xs="ms-2"
            ngClass.xs="small ms-1"
            [routerLink]="link.link"
            (click)="emitEvent(link.emit)"
            *ngIf="!link.text && !link.import"
        >
            <mat-icon [svgIcon]="link.icon" *ngIf="link.icon"></mat-icon>
        </button>
    </ng-container>
</div>
